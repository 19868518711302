.empty-animation{
    animation-name: zoomInStable; 
    animation-duration: 3s; 
    animation-iteration-count: 1;
    animation-timing-function: linear;
  
  -webkit-animation-name: zoomInStable; 
    -webkit-animation-duration: 3s; 
    -webkit-animation-iteration-count: 1;
    -webkit-animation-timing-function: linear;
  
  -moz-animation-name: zoomInStable; 
    -moz-animation-duration: 3s; 
    -moz-animation-iteration-count: 1;
    -moz-animation-timing-function: linear;
  
  -ms-animation-name: zoomInStable; 
    -ms-animation-duration: 3s; 
    -ms-animation-iteration-count: 1;
    -ms-animation-timing-function: linear;
  
  -o-animation-name: zoomInStable; 
    -o-animation-duration: 3s; 
    -o-animation-iteration-count: 1;
    -o-animation-timing-function: linear;
}

.empty-animation{
    animation-name: zoomInStable; 
    animation-duration: 3s; 
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  
  -webkit-animation-name: zoomInStable; 
    -webkit-animation-duration: 3s; 
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
  
  -moz-animation-name: zoomInStable; 
    -moz-animation-duration: 3s; 
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
  
  -ms-animation-name: zoomInStable; 
    -ms-animation-duration: 3s; 
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
  
  -o-animation-name: zoomInStable; 
    -o-animation-duration: 3s; 
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear;
}

.empty-animation{
    animation-name: slideInUp; 
    animation-duration: 2s; 
    animation-iteration-count: 1;
    animation-timing-function: linear;
  
  -webkit-animation-name: slideInUp; 
    -webkit-animation-duration: 2s; 
    -webkit-animation-iteration-count: 1;
    -webkit-animation-timing-function: linear;
  
  -moz-animation-name: slideInUp; 
    -moz-animation-duration: 2s; 
    -moz-animation-iteration-count: 1;
    -moz-animation-timing-function: linear;
  
  -ms-animation-name: slideInUp; 
    -ms-animation-duration: 2s; 
    -ms-animation-iteration-count: 1;
    -ms-animation-timing-function: linear;
  
}

.empty-animation{
    animation-name: slideInRight; 
    animation-duration: 2s; 
    animation-iteration-count: 1;
    animation-timing-function: linear;
  
  -webkit-animation-name: slideInRight; 
    -webkit-animation-duration: 2s; 
    -webkit-animation-iteration-count: 1;
    -webkit-animation-timing-function: linear;
  
  -moz-animation-name: slideInRight; 
    -moz-animation-duration: 2s; 
    -moz-animation-iteration-count: 1;
    -moz-animation-timing-function: linear;
  
  -ms-animation-name: slideInRight; 
    -ms-animation-duration: 2s; 
    -ms-animation-iteration-count: 1;
    -ms-animation-timing-function: linear;
  
}

.empty-animation{
    animation-name: slideInLeft; 
    animation-duration: 2s; 
    animation-iteration-count: 1;
    animation-timing-function: linear;
  
  -webkit-animation-name: slideInLeft; 
    -webkit-animation-duration: 2s; 
    -webkit-animation-iteration-count: 1;
    -webkit-animation-timing-function: linear;
  
  -moz-animation-name: slideInLeft; 
    -moz-animation-duration: 2s; 
    -moz-animation-iteration-count: 1;
    -moz-animation-timing-function: linear;
  
  -ms-animation-name: slideInLeft; 
    -ms-animation-duration: 2s; 
    -ms-animation-iteration-count: 1;
    -ms-animation-timing-function: linear;
  
}

.empty-animation{
    animation-name: slideInUp; 
    animation-duration: 2s; 
    animation-iteration-count: 1;
    animation-timing-function: linear;
  
  -webkit-animation-name: slideInUp; 
    -webkit-animation-duration: 2s; 
    -webkit-animation-iteration-count: 1;
    -webkit-animation-timing-function: linear;
  
  -moz-animation-name: slideInUp; 
    -moz-animation-duration: 2s;
    -moz-animation-iteration-count: 1;
    -moz-animation-timing-function: linear;
  
  -ms-animation-name: slideInUp; 
    -ms-animation-duration: 2s; 
    -ms-animation-iteration-count: 1;
    -ms-animation-timing-function: linear;
  
}

@keyframes rotateme {
    
     0% {
        transform: rotate(0deg);
        opacity: 1;
      }

    50% {
        transform: rotate(180deg);
      }
    100% {
        transform: rotate(360deg);
        opacity: 1;
      }
}

.story-section .image-col .round-stamp:before,
.banner-section .book-btn:before,
.main-footer .info-col .separator span,
.intro-section .separator span,
.testimonials-section .separator span,
.menu-tabs .buttons .tab-buttons .tab-btn:after,
.reserve-section .info-col .discount-info:before{
    animation-name: rotateme;
    animation-duration: 15s; 
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    
    -webkit-animation-name: rotateme; 
    -webkit-animation-duration: 15s; 
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    
    -moz-animation-name: rotateme; 
    -moz-animation-duration: 15s; 
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    
    -ms-animation-name: rotateme; 
    -ms-animation-duration: 15s; 
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    
    -o-animation-name: rotateme; 
    -o-animation-duration: 15s; 
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear;
}

@keyframes rotateme2 {
    
     0% {
        transform: rotate(0deg);
        opacity: 1;
      }

    50% {
        transform: rotate(-180deg);
      }
    100% {
        transform: rotate(-360deg);
        opacity: 1;
      }
}

.empty-animation{
    animation-name: rotateme2;
    animation-duration: 15s; 
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    
    -webkit-animation-name: rotateme2; 
    -webkit-animation-duration: 15s; 
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    
    -moz-animation-name: rotateme2; 
    -moz-animation-duration: 15s; 
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    
    -ms-animation-name: rotateme2; 
    -ms-animation-duration: 15s; 
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    
    -o-animation-name: rotateme2; 
    -o-animation-duration: 15s; 
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear;
}

/* Bob Up Down*/

@-webkit-keyframes float_up_down {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    opacity: 1;
  }

  50% {
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes float_up_down {
  0% {
    transform: translateY(0px);
    transform: translateY(0px);
    opacity: 1;
  }

  50% {
    transform: translateY(30px);
    transform: translateY(30px);
    opacity: 1;
  }

  100% {
    transform: translateY(0px);
    transform: translateY(0px);
    opacity: 1;
  }
}

.menu-section .left-bg,
.menu-section .right-bg,
.we-offer-section .right-top-bg,
.we-offer-section .left-bot-bg,
.special-dish .content-col .badge-icon{
    animation-name: float_up_down; 
    animation-duration: 5s; 
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    
    -webkit-animation-name: float_up_down; 
    -webkit-animation-duration: 5s; 
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    
    -moz-animation-name: float_up_down; 
    -moz-animation-duration: 5s; 
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    
    -ms-animation-name: float_up_down; 
    -ms-animation-duration: 5s; 
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    
    -o-animation-name: float_left_right; 
    -o-animation-duration: 5s; 
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear;
}

/* Bob */
@-webkit-keyframes float_left_right {
  0% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
    opacity: 1;
  }

  50% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
    opacity: 0.40;
  }

  100% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
    opacity: 1;
  }
}

@keyframes float_left_right {
  0% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
    opacity: 1;
  }

  50% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
    opacity: 0.40;
  }

  100% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
    opacity: 1;
  }
}

.empty-animation{
    animation-name: float_left_right; 
    animation-duration: 30s; 
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  
  -webkit-animation-name: float_left_right; 
    -webkit-animation-duration: 30s; 
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
  
  -moz-animation-name: float_left_right; 
    -moz-animation-duration: 30s; 
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
  
  -ms-animation-name: float_left_right; 
    -ms-animation-duration: 30s; 
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
  
  -o-animation-name: float_left_right; 
    -o-animation-duration: 30s; 
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear;
}

@-webkit-keyframes float_left_right_two {
  0% {
    -webkit-transform: translateX(-30px);
    opacity: 1;
  }

  50% {
    -webkit-transform: translateX(0px);
    opacity: 0.80;
  }

  100% {
    -webkit-transform: translateX(-30px);
    opacity: 1;
  }
}

@keyframes float_left_right_two {
  0% {
    transform: translateX(-30px);
    opacity: 1;
  }

  50% {
    transform: translateX(0px);
    opacity: 0.80;
  }

  100% {
    transform: translateX(-30px);
    opacity: 1;
  }
}

.empty-enimation{
    animation-name: float_left_right_two; 
    animation-duration: 5s; 
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  
  -webkit-animation-name: float_left_right_two; 
    -webkit-animation-duration: 5s; 
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
  
  -moz-animation-name: float_left_right_two; 
    -moz-animation-duration: 5s; 
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
  
  -ms-animation-name: float_left_right_two; 
    -ms-animation-duration: 5s; 
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
  
  -o-animation-name: float_left_right_two; 
    -o-animation-duration: 5s; 
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear;
}


@-webkit-keyframes zoom-fade {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }

  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
}

.empty-animation{
    animation-name: zoom-fade; 
    animation-duration: 6s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    
    -webkit-animation-name: zoom-fade; 
    -webkit-animation-duration: 6s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    
    -moz-animation-name: zoom-fade; 
    -moz-animation-duration: 6s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    
    -ms-animation-name: zoom-fade; 
    -ms-animation-duration: 6s;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    
    -o-animation-name: zoom-fade; 
    -o-animation-duration: 6s; 
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear;
}















/* Dots Animations */

.ct-dot-animated {
    position: absolute;
    width: 100%;
    height: 102%;
    top: 0;
    left: 0;
    z-index:1;
}

.ct-dot-animated.top-slider {
    height: calc(100% + 330px);
    height: -webkit-calc(100% + 330px);
    margin-top: -330px
}

.ct-dot-animated .ct-dot-item {
    background-color: rgba(255, 255, 255, .2);
    width: 1px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 10%;
}

.ct-dot-animated .ct-dot-item span {
    width: 3px;
    height: 10px;
    -webkit-border-radius: 50px;
    -khtml-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
    border-radius: 50px;
    background-color: #ffffff;
    position: absolute;
    bottom: 14%;
    left: 0px
}

.ct-dot-animated .ct-dot-item:nth-child(1) span {
    background-color: #ffffff;
    -webkit-animation: dotAnimatedBottomToTop1 20s infinite linear;
    -moz-animation: dotAnimatedBottomToTop1 20s infinite linear;
    -o-animation: dotAnimatedBottomToTop1 20s infinite linear;
    animation: dotAnimatedBottomToTop1 20s infinite linear;
}

.ct-dot-animated .ct-dot-item:nth-child(2) {
    left: 50%
}

.ct-dot-animated .ct-dot-item:nth-child(2) span {
    background-color: #ffffff;
    bottom: 64%;
    -webkit-animation: dotAnimatedBottomToTop2 45s infinite linear;
    -moz-animation: dotAnimatedBottomToTop2 45s infinite linear;
    -o-animation: dotAnimatedBottomToTop2 45s infinite linear;
    animation: dotAnimatedBottomToTop2 45s infinite linear
}

.ct-dot-animated .ct-dot-item:nth-child(3) {
    left: 90%
}

.ct-dot-animated .ct-dot-item:nth-child(3) span {
    background-color: #ffffff;
    bottom: 5%;
    -webkit-animation: dotAnimatedBottomToTop3 30s infinite linear;
    -moz-animation: dotAnimatedBottomToTop3 30s infinite linear;
    -o-animation: dotAnimatedBottomToTop3 30s infinite linear;
    animation: dotAnimatedBottomToTop3 30s infinite linear
}

.ct-dot-animated .ct-dot-item:nth-child(4) {
    left: 60%
}

.ct-dot-animated .ct-dot-item:nth-child(4) span {
    background-color: #ffffff;
    bottom: 78%;
    -webkit-animation: dotAnimatedBottomToTop4 35s infinite linear;
    -moz-animation: dotAnimatedBottomToTop4 35s infinite linear;
    -o-animation: dotAnimatedBottomToTop4 35s infinite linear;
    animation: dotAnimatedBottomToTop4 35s infinite linear
}

.ct-dot-animated .ct-dot-item:nth-child(5) {
    left: 80%
}

.ct-dot-animated .ct-dot-item:nth-child(5) span {
    background-color: #ffffff;
    bottom: 46%;
    -webkit-animation: dotAnimatedBottomToTop5 30s infinite linear;
    -moz-animation: dotAnimatedBottomToTop5 30s infinite linear;
    -o-animation: dotAnimatedBottomToTop5 30s infinite linear;
    animation: dotAnimatedBottomToTop5 30s infinite linear
}

.ct-dot-animated .ct-dot-item:nth-child(6) {
    left: auto;
    right: 0
}

.ct-dot-animated .ct-dot-item:nth-child(6) span {
    background-color: #ffffff;
    bottom: 27%;
    -webkit-animation: dotAnimatedBottomToTop6 30s infinite linear;
    -moz-animation: dotAnimatedBottomToTop6 30s infinite linear;
    -o-animation: dotAnimatedBottomToTop6 30s infinite linear;
    animation: dotAnimatedBottomToTop6 30s infinite linear
}

.ct-dot-animated .ct-dot-container {
    max-width: 1004px;
    height: 100%;
    margin: 0 auto;
    position: relative
}

.error404 .site-content {
    padding-top: 195px !important;
    padding-bottom: 0 !important
}


@keyframes dotAnimatedBottomToTop1 {
    0% {
        bottom: 14%
    }
    50% {
        bottom: 100%
    }
    100% {
        bottom: 14%
    }
}

@keyframes dotAnimatedBottomToTop2 {
    0% {
        bottom: 64%
    }
    50% {
        bottom: 0
    }
    100% {
        bottom: 100%
    }
}

@keyframes dotAnimatedBottomToTop3 {
    0% {
        bottom: 5%
    }
    50% {
        bottom: 100%
    }
    100% {
        bottom: 5%
    }
}

@keyframes dotAnimatedBottomToTop4 {
    0% {
        bottom: 78%
    }
    50% {
        bottom: 0
    }
    100% {
        bottom: 78%
    }
}

@keyframes dotAnimatedBottomToTop5 {
    0% {
        bottom: 46%
    }
    50% {
        bottom: 0
    }
    100% {
        bottom: 46%
    }
}

@keyframes dotAnimatedBottomToTop6 {
    0% {
        bottom: 27%
    }
    50% {
        bottom: 100%
    }
    100% {
        bottom: 27%
    }
}

@keyframes dotAnimatedBottomToTop2 {
    0% {
        bottom: 64%
    }
    50% {
        bottom: 0
    }
    100% {
        bottom: 100%
    }
}

@keyframes dotAnimatedBottomToTop3 {
    0% {
        bottom: 5%
    }
    50% {
        bottom: 100%
    }
    100% {
        bottom: 5%
    }
}

@keyframes dotAnimatedBottomToTop4 {
    0% {
        bottom: 78%
    }
    50% {
        bottom: 0
    }
    100% {
        bottom: 78%
    }
}

@keyframes dotAnimatedBottomToTop5 {
    0% {
        bottom: 46%
    }
    50% {
        bottom: 0
    }
    100% {
        bottom: 46%
    }
}

@keyframes dotAnimatedBottomToTop6 {
    0% {
        bottom: 27%
    }
    50% {
        bottom: 100%
    }
    100% {
        bottom: 27%
    }
}

@keyframes dotAnimatedBottomToTop3 {
    0% {
        bottom: 5%
    }
    50% {
        bottom: 100%
    }
    100% {
        bottom: 5%
    }
}

@keyframes dotAnimatedBottomToTop4 {
    0% {
        bottom: 78%
    }
    50% {
        bottom: 0
    }
    100% {
        bottom: 78%
    }
}

@keyframes dotAnimatedBottomToTop4 {
    0% {
        bottom: 78%
    }
    50% {
        bottom: 0
    }
    100% {
        bottom: 78%
    }
}

@keyframes dotAnimatedBottomToTop5 {
    0% {
        bottom: 46%
    }
    50% {
        bottom: 0
    }
    100% {
        bottom: 46%
    }
}

@keyframes dotAnimatedBottomToTop6 {
    0% {
        bottom: 27%
    }
    50% {
        bottom: 100%
    }
    100% {
        bottom: 27%
    }
}









@-webkit-keyframes icon-bounce {
  0%, 100%, 20%, 50%, 80% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  60% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}
@keyframes icon-bounce {
  0%, 100%, 20%, 50%, 80% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  60% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}






@-webkit-keyframes marquee {
    from {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    to {
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
    }
  }
  
  @keyframes marquee {
    from {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    to {
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
    }
  }
  @-webkit-keyframes marquee2 {
    to {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    from {
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
    }
  }
  @keyframes marquee2 {
    to {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    from {
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
    }
  }
  
  
  @-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}

@keyframes shine {
  100% {
    left: 125%;
  }
}




@-webkit-keyframes circle {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}
@keyframes circle {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}




@-webkit-keyframes ripple {
    70% {box-shadow: 0 0 0 70px rgba(253, 167, 0, 0);}
    100% {box-shadow: 0 0 0 0 rgba(253, 167, 0, 0);}
}

@keyframes ripple {
    70% {box-shadow: 0 0 0 70px rgba(253, 167, 0, 0);}
    100% {box-shadow: 0 0 0 0 rgba(253, 167, 0, 0);}
}